<template>
  <div style="margin-left: 30px">
    <!-- 角色名称 -->
    <div class="box">
      <div class="main">
        <p>{{ $t("account.roleName") }}: {{ authorityName }}</p>
      </div>
    </div>
    <!-- 分割线 -->
    <el-divider></el-divider>

    <!-- 权限设置 -->
    <h2 class="permission">{{ $t("account.permissionSetting") }}：</h2>
    <!-- menuDatas -->
    <el-tree
      :data="menuDatas"
      show-checkbox
      node-key="id"
      :default-expand-all="true"
      class="tree"
      ref="tree"
      @check="t"
      :default-checked-keys="checkeds"
    ></el-tree>

    <!-- <div class="permissionMain"> -->
    <!-- Dashboard -->
    <!-- <el-tree
        :data="home"
        show-checkbox
        node-key="id"
        :default-expand-all="true"
        class="tree"
      >
    </el-tree>-->
    <!-- Orders -->
    <!-- <el-tree
        :data="menuDatas"
        show-checkbox
        node-key="id"
        :default-expand-all="true"
        class="tree"
      >
    </el-tree>-->
    <!-- commodity -->
    <!-- <el-tree
        :data="commodity"
        show-checkbox
        node-key="id"
        :default-expand-all="true"
        class="tree"
      >
    </el-tree>-->
    <!-- purchase -->
    <!-- <el-tree
        :data="purchase"
        show-checkbox
        node-key="id"
        :default-expand-all="true"
        class="tree"
      >
    </el-tree>-->
    <!-- Inquiry -->
    <!-- <el-tree
        :data="Inquiry"
        show-checkbox
        node-key="id"
        :default-expand-all="true"
        class="tree"
      >
    </el-tree>-->
    <!-- FinancialCenter -->
    <!-- <el-tree
        :data="FinancialCenter"
        show-checkbox
        node-key="id"
        :default-expand-all="true"
        class="tree"
      >
    </el-tree>-->
    <!-- advertisement -->
    <!-- <el-tree
        :data="advertisement"
        show-checkbox
        node-key="id"
        :default-expand-all="true"
        class="tree"
      >
    </el-tree>-->
    <!-- subscribe -->
    <!-- <el-tree
        :data="subscribe"
        show-checkbox
        node-key="id"
        :default-expand-all="true"
        class="tree"
      >
    </el-tree>-->
    <!-- Account -->
    <!-- <el-tree
        :data="Account"
        show-checkbox
        node-key="id"
        :default-expand-all="true"
        class="tree"
      >
    </el-tree>-->
    <!-- </div> -->

    <div class="btn">
      <el-button class="cancel" @click="cancel">
        {{
        $t("common.cancel")
        }}
      </el-button>
      <el-button class="determine" @click="determine">
        {{
        $t("financialCenter.determine")
        }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { postData } from "@//helper/api";
export default {
  data() {
    return {
      // Dashboard: [
      //   {
      //     id: 1,
      //     label: "Dashboard",
      //     children: [
      //       // Store information
      //       {
      //         id: 2,
      //         label: "Store information",
      //         children: [
      //           {
      //             id: 1,
      //             label: "Visits",
      //           },
      //           {
      //             id: 2,
      //             label: "Number of collections",
      //           },
      //           {
      //             id: 3,
      //             label: "Quantity of goods",
      //           },
      //           {
      //             id: 4,
      //             label: "Store score",
      //           },
      //         ],
      //       },
      //       // Orders Trackers
      //       {
      //         id: 3,
      //         label: "Orders Trackers",
      //         children: [
      //           {
      //             id: 1,
      //             label: "New Orders",
      //           },
      //           {
      //             id: 2,
      //             label: "In Progress",
      //           },
      //           {
      //             id: 3,
      //             label: "Shipped",
      //           },
      //           {
      //             id: 4,
      //             label: "Failed",
      //           },
      //         ],
      //       },
      //       // Proportion of sales
      //       {
      //         id: 4,
      //         label: "Proportion of sales",
      //       },
      //       // report
      //       {
      //         id: 5,
      //         label: "report",
      //         children: [
      //           {
      //             id: 1,
      //             label: "Gross Sales",
      //           },
      //           {
      //             id: 2,
      //             label: "Earnings",
      //           },
      //           {
      //             id: 3,
      //             label: "Sold Items",
      //           },
      //           {
      //             id: 4,
      //             label: "Orders Received",
      //           },
      //         ],
      //       },
      //       // Orders Received
      //       {
      //         id: 6,
      //         label: "Orders Received",
      //         children: [
      //           {
      //             id: 1,
      //             label: "Advertisement view",
      //           },
      //           {
      //             id: 2,
      //             label: "download",
      //           },
      //           {
      //             id: 3,
      //             label: "Date selection",
      //           },
      //         ],
      //       },
      //       // orders
      //       {
      //         id: 7,
      //         label: "orders",
      //         children: [
      //           {
      //             id: 1,
      //             label: "View now",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // ],
      // Orders: [
      //   {
      //     id: 1,
      //     label: "Orders",
      //     children: [
      //       {
      //         id: 2,
      //         label: "whole",
      //         children: [
      //           {
      //             id: 1,
      //             label: "Order list",
      //           },
      //           {
      //             id: 2,
      //             label: "Upload product",
      //           },
      //           {
      //             id: 3,
      //             label: "deliver goods",
      //           },
      //           {
      //             id: 4,
      //             label: "Reject return",
      //           },
      //           {
      //             id: 5,
      //             label: "Process returns",
      //           },
      //           {
      //             id: 6,
      //             label: "Refuse to exchange",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // ],
      // commodity: [
      //   {
      //     id: 1,
      //     label: "commodity",
      //     children: [
      //       {
      //         id: 2,
      //         label: "Commodity management",
      //         children: [
      //           {
      //             id: 1,
      //             label: "Product list",
      //           },
      //           {
      //             id: 2,
      //             label: "Product addition",
      //           },
      //           {
      //             id: 3,
      //             label: "Commodity editor",
      //           },
      //           {
      //             id: 4,
      //             label: "Product deletion",
      //           },
      //           {
      //             id: 5,
      //             label: "Add category",
      //           },
      //           {
      //             id: 6,
      //             label: "Modify classification",
      //           },
      //           {
      //             id: 7,
      //             label: "Delete classification",
      //           },
      //         ],
      //       },
      //       {
      //         id: 3,
      //         label: "Picture space",
      //         children: [
      //           {
      //             id: 1,
      //             label: "Add picture",
      //           },
      //           {
      //             id: 2,
      //             label: "Delete picture",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // ],
      // purchase: [
      //   {
      //     id: 1,
      //     label: "purchase",
      //     children: [
      //       {
      //         id: 2,
      //         label: "Procurement Center",
      //         children: [
      //           {
      //             id: 1,
      //             label: "Purchase list view",
      //           },
      //           {
      //             id: 2,
      //             label: "Purchase reply quotation",
      //           },
      //           {
      //             id: 3,
      //             label: "Purchase reply information notice",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // ],
      // Inquiry: [
      //   {
      //     id: 1,
      //     label: "Inquiry",
      //     children: [
      //       {
      //         id: 2,
      //         label: "inquiry list",
      //         children: [
      //           {
      //             id: 1,
      //             label: "view list",
      //           },
      //           {
      //             id: 2,
      //             label: "Reply operation",
      //           },
      //           {
      //             id: 3,
      //             label: "Purchase reply information notice",
      //           },
      //         ],
      //       },
      //       {
      //         id: 3,
      //         label: "Inquiry reply information",
      //         children: [
      //           {
      //             id: 1,
      //             label: "Information viewing",
      //           },
      //           {
      //             id: 2,
      //             label: "Reply operation",
      //           },
      //           {
      //             id: 3,
      //             label: "Purchase reply information notice",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // ],
      // FinancialCenter: [
      //   {
      //     id: 1,
      //     label: "Financial Center",
      //     children: [
      //       {
      //         id: 2,
      //         label: "Financial Center",
      //         children: [
      //           {
      //             id: 1,
      //             label: "View withdrawal amount",
      //           },
      //           {
      //             id: 2,
      //             label: "Withdrawal operation",
      //           },
      //         ],
      //       },
      //       {
      //         id: 3,
      //         label: "Transaction record",
      //         children: [
      //           {
      //             id: 1,
      //             label: "Transaction record",
      //           },
      //           {
      //             id: 2,
      //             label: "Withdrawal operation",
      //           },
      //           {
      //             id: 3,
      //             label: "Bank card view",
      //           },
      //           {
      //             id: 4,
      //             label: "Add bank card",
      //           },
      //           {
      //             id: 5,
      //             label: "Password modification",
      //           },
      //           {
      //             id: 6,
      //             label: "Forget password operation",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // ],
      // advertisement: [
      //   {
      //     id: 1,
      //     label: "advertisement",
      //     children: [
      //       {
      //         id: 2,
      //         label: "Advertising management",
      //         children: [
      //           {
      //             id: 1,
      //             label: "Preview advertising",
      //           },
      //           {
      //             id: 2,
      //             label: "Edit advertising content",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // ],
      // subscribe: [
      //   {
      //     id: 1,
      //     label: "subscribe",
      //     children: [
      //       {
      //         id: 2,
      //         label: "subscription management",
      //         children: [
      //           {
      //             id: 1,
      //             label: "Subscription payment",
      //           },
      //           {
      //             id: 2,
      //             label: "Subscription view",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // ],
      // Account: [
      //   {
      //     id: 1,
      //     label: "Account",
      //     children: [
      //       {
      //         id: 2,
      //         label: "Forget password operation",
      //         children: [
      //           {
      //             id: 1,
      //             label: "Modify personal information",
      //           },
      //           {
      //             id: 2,
      //             label: "Modify personal information",
      //           },
      //         ],
      //       },
      //       {
      //         id: 3,
      //         label: "Permission setting",
      //         children: [
      //           {
      //             id: 1,
      //             label: "Permission setting",
      //           },
      //           {
      //             id: 2,
      //             label: "Add role",
      //           },
      //           {
      //             id: 3,
      //             label: "Role modification",
      //           },
      //           {
      //             id: 4,
      //             label: "Delete people",
      //           },
      //           {
      //             id: 5,
      //             label: "Freeze operation",
      //           },
      //           {
      //             id: 6,
      //             label: "View personnel list",
      //           },
      //           {
      //             id: 7,
      //             label: "Add people",
      //           },
      //           {
      //             id: 8,
      //             label: "Modifier",
      //           },
      //           {
      //             id: 9,
      //             label: "Delete people",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // ],
      authorityName: "", // 角色名称
      id: "", // 角色ID
      menuData: [],
      menus: [],
      checkeds: []

      // home: [],
    };
  },
  created() {
    this.menu();
  },
  mounted() {
    // console.log("xxxxxxxxxxxx1", this.$route.query.row)
    const row = JSON.parse(decodeURIComponent(this.$route.query.row));
    this.roleList(row);
  },
  computed: {
    menuDatas: function () {
      return this.menuData.map((v) => {
        return {
          id: v.ID,
          label: this.$t("menu." + v.name),
          path: v.path,
          children:
            v.children &&
            v.children.map((c) => {
              return {
                id: c.ID,
                label: this.$t("menu." + c.name),
                path: c.path,
              };
            }),
        };
      });
    },
  },

  methods: {
    // 角色列表
    roleList(row) {
      this.authorityName = row.authorityName;
      this.id = row.authorityId;
      console.log("this.id:", this.id);
    },
    // 权限列表
    menu() {
      console.log(';;menu');
      postData("POST", "authorityMenu/getMainMenuList", {}).then(res => {
        console.log("权限列表:", res);
        this.menuData = res.data;
        // console.log("this.menuData:",this.menuData);
        // this.menuDatas = this.menuData.map((v) => {
        //   return {
        //     id: v.ID,
        //     label: this.$t("menu." + v.name),
        //     path: v.path,
        //     children:
        //       v.children &&
        //       v.children.map((c) => {
        //         return {
        //           id: c.ID,
        //           label: this.$t("menu." + c.name),
        //           path: c.path,
        //         };
        //       }),
        //   };
        // });
        // console.log("this.menuDatas", this.menuDatas);
        this.checked();
        // this.home.push(this.menuDatas[0]);
        // this.order.push(this.menuDatas[1])
        // this.
        // this.children = this.order.map((v) => {
        //   return v.children;
        // });
        // console.log("this.children:", this.children);
        // this.childrens = this.children.map((v) => {
        //   return v;
        // });
        // console.log("this.childrens:", this.childrens);
      });
    },
    // 选择框选中
    checked() {
      postData("POST", "authorityMenu/getList", {
        id: this.id
      }).then(res => {
        console.log("选中：", res);
        const data = res.data;
        const id = data
          .map(v => {
            if (v.children) {
              return v.children.map(c => {
                return c.menuId;
              });
            } else {
              return [v.menuId];
            }
          })
          .reduce((total, num) => {
            console.log(total, num);
            return (total || []).concat(num);
          });
        console.log("id", id);
        this.checkeds = id;
        // this.checkeds.push(
        //   id.map((item) => {
        //     console.log("item", item.id);
        //     item.id,
        //       item.children.map((i) => {
        //         console.log("i", i.id);
        //         i.id;
        //       });
        //   })
        // );
        // console.log("this.checkeds:", this.checkeds);
      });
    },
    t() {
      let parentArr = this.$refs.tree.getHalfCheckedKeys(); // 获取父id
      let childArr = this.$refs.tree.getCheckedKeys(); // 获取子id
      //this.addRoleForm.rolePower = parentArr.concat(childArr)

      this.menus = parentArr.concat(childArr); // 连接父id和子id
      console.log("parentArr:", this.menus);
    },
    // 选择框数据
    treeCheck(item, index, i) {
      console.log("item:", item, "index:", index, "i:", i);
      if (index === true) {
        this.menus.push(item.id);

        // if (item.children) {
        //   console.log("item.children",item.children);
        // } else {
        //   this.menus.push(item.id);
        // }
      } else {
        this.menus.splice(-1, 1);
      }

      // this.menus = this.menuDatas.forEach((v) => {
      //   this.menuDatas.indexOf(v.id) === -1;
      // });
      console.log("this.menus:", this.menus);
    },
    // 返回
    cancel() {
      this.$router.push("/account/permission");
    },
    // 确定
    determine() {
      console.log("this.id:", this.id);
      let newMenu = [];
      this.menus.forEach(ele => {
        newMenu.push({
          id: ele
        });
      });
      console.log("newMenu:", newMenu);
      postData("POST", "authorityMenu/add", {
        menus: newMenu,
        authorityId: this.id
      }).then(res => {
        console.log("determine:", res);
        this.$router.push("/account/permission");
      });
    }
  }
};
</script>

<style scoped>
.box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.main {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  margin-right: 16px;
  text-align: center;
}
.btn {
  margin-top: 100px;
  height: 80px;
  background: #fff;
  display: flex;
  justify-content: center;
}
.btn .cancel {
  width: 131px;
  background: #fae1e0;
  color: #ff0a0a;
  border: none;
  font-size: 16px;
  margin-right: 40px;
}
.btn .determine {
  width: 131px;
  background: #ff0a0a;
  font-size: 16px;
  color: #fff;
  border: none;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: -30px;
}
.preview {
  width: 184px;
  background: #fae1e0;
  font-size: 15px;
  color: #ff0a0a;
  border: none;
}
.permission {
  font-size: 20px;
  margin-top: 43px;
  margin-bottom: 34px;
}
.preservation {
  width: 184px;
  background: #ff0a0a;
  font-size: 15px;
  color: #fff;
  border: none;
}
.permissionMain {
  display: flex;
  flex-wrap: wrap;
}
.tree {
  margin-bottom: 69px;
  background: none;
  margin-right: 30%;
}
</style>